.tribune-wrapper {
  border:1px dotted black;
  box-sizing: border-box;
  padding: 1rem;
  .sectors {
    width: 100%;
    overflow-x: scroll;
    white-space:nowrap;
    padding: 1rem;
    box-sizing: border-box;
    .sector {
      display:inline-block;
      border: 1px solid black;
      padding: 1rem;
      box-sizing: border-box;
    }
  }
}
