.tickets {
  .tribunes {
    .tribune {
      background: #EEEEEE;
      &:nth-child(2n+2){
        background: #F2F2F2;
      }
      &:hover {
        border: 1px dotted black;
        box-shadow: 0 0 5px black;
      }
      h3, h4 {
        text-align: center;
      }
      &.no-numbers{
        .sectors{
          .sector{
            border: 1px solid black;
            cursor: pointer;
            position: relative;
            .available-places {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translateX(-50%) translateY(-50%);
              background: white;
              padding: 0.25rem;
              margin-top: 0.25rem;
              h5 {
                font-size: 1.5rem;
                font-weight: 500;
              }
              small {
                font-size: 1rem;
                font-weight: 500;
              }
            }
            .rows{
              .row{
                .row-label{
                  display:none;
                }
                .seat {
                  border:1px dashed gray;
                  &.disabled {
                    border: none;
                  }
                  &.busy-ticket:not(.disabled) {
                    background: gray;
                    color: white;
                    &.in-use {
                      background: yellow;
                      color: black;
                    }
                  }
                  &.busy-carnet:not(.disabled) {
                    background: #337ab7;
                    color: white;
                    &.in-use {
                      background: yellow;
                      color: black;
                    }
                  }
                  &.selected:not(.disabled) {
                    background: green;
                    color: white;
                  }
                }
              }
            }
          }
        }
      }
      padding: 1rem;
      box-sizing: border-box;
      overflow-x: scroll;
      &.has-number{
        .sectors {
          .sector {
            .rows {
              .row {
                .seat {
                  &:not(.disabled){
                    cursor: pointer;
                  }
                  &.disabled, &.busy-carnet, &.busy-ticket {
                    cursor: default;
                  }
                  &:hover:not(.disabled), &.busy-ticket:not(.disabled) {
                    background: gray;
                    color: white;
                    &.in-use {
                      background: yellow;
                      color: black;
                    }
                  }
                  &.busy-carnet:not(.disabled) {
                    background: #337ab7;
                    color: white;
                    &.in-use {
                      background: #e0e00b;;
                      color: black;
                    }
                  }
                  &.selected {
                    background: green;
                    color: white;
                  }
                }
              }
            }
          }
        }
      }
      .sectors {
        display: block;
        white-space: nowrap;
        text-align: center;
        overflow-x: scroll;
        padding: 1rem;
        margin-right: 2rem;
        .sector {
          display: inline-block;
          margin: 0;
          transform: scale(80%);
          transition: transform 0.3s ease-in;
          padding: 0 1rem;
          &:hover {
            transform: scale(100%);
            border: 1px solid black;
            background: white;
          }
          .rows {
            .row {
              display: block;
              span {
                display: inline-block;
                height: 1.2rem;
                line-height: 1rem;
                text-align: center;
                padding: 0;
              }
              .row-label {
                background: green;
                border-radius: 10rem;
                color: white;
                font-size: 1rem;
                line-height: 1.5rem;
                height: 2rem;
                width: 2rem;
              }
              .seat {
                width: 1.5rem;
              }
            }
          }
        }
      }
    }
  }
}
.present-tickets-tribune {
  padding: 1rem 0;
  margin: 1rem 0;
  .present-tickets-sector {
    width: 100%;
    vertical-align: top;
    margin: 0 0.5rem;
  }
}
.select-ticket {
  overflow: hidden;
  .select-ticket-type {
    width: 10rem;
    float: right;
  }
}
.admin-comment {
  width: 300px;
  padding: 0.5rem;
  &.expected {
    background: rgba(255,0,0,0.5);
    border:3px solid rgb(255,0,0);
    box-shadow: 0 0 5px red;
    input {
      border:3px solid rgb(255,0,0);
    }
  }
}
.universal-tickets-sticker {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 250px;
  z-index: 9999;
  background: rgba(55,55,55,0.4);
  text-align: center;
  padding: 0.75rem;
  box-sizing:border-box;
  &:hover {
    background: rgba(55,55,55,1);
    color: white;
  }
}
