.default {
  background: gray;
}
.error {
  background: red;
}
.success {
  background: green;
}
.panel {
  position: absolute;
  left:0;
  top:0;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 1rem;
  box-sizing: border-box;
  text-align: center;
}
