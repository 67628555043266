.home-component{
  max-width: 800px;
  margin:0 auto;
  .event-link {
    cursor: pointer;
    &:hover {
      box-shadow: 0 0 1px black;
    }
  }
}
