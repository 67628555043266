
.carousel-wrapper {
  position: relative;
  .carousel-item {
    height: 80vh;
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
    }
  }
  .events-info {
    z-index: 99999999;
    position: absolute;
    left: 2rem;
    top: 2rem;
    width: 20rem;
    box-shadow: 0 0 1px black;
    .card-header {
      position: relative;
      img {
        width: 40%;
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(50%) translateY(-50%);
      }
    }
    img {
      width: 25%;
      display: block;
      margin: 1rem auto 0;
    }
  }
  .images {
    margin-top: 1rem;
    text-align: center;
    img {
      height: 2.5rem;
      width: auto;
      margin: 0.5rem;
    }
  }
}
