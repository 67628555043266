.Dashboard {
  .toggle-minimize {
    cursor: pointer;
    padding: 1rem;
    box-shadow: 0 0 15px black;
    &:hover {
      font-weight: 900;
      box-shadow: 0 0 5px black;
    }
  }
  > nav {
    float: left;
    width: 20%;
    box-shadow: 0 0 15px black;
    img.logo {
      width: 50%;
      margin: 2rem auto;
      float: none;
      display: block;
    }
  }
  > section {
    float: left;
    padding-left: 1rem;
    margin-top: 2rem;
    box-sizing: border-box;
    width: 80%;
  }
  &.minimized {
    > nav {
      display: none;
    }
    > section {
      width: 100%;
    }
  }
}
