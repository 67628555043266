.carnets {
  .tribunes {
    .tribune {
      background: #EEEEEE;
      &:nth-child(2n+2){
        background: #F2F2F2;
      }
      &:hover {
        border: 1px dotted black;
        box-shadow: 0 0 5px black;
      }
      h3, h4 {
        text-align: center;
      }
      &.no-numbers{
        .sectors{
          .sector{
            border: 1px solid black;
            cursor: pointer;
            position: relative;
            .available-places {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translateX(-50%) translateY(-50%);
            }
            .rows{
              .row{
                .row-label{
                  display:none;
                }
              }
            }
          }
        }
      }
      padding: 1rem;
      box-sizing: border-box;
      overflow-x: scroll;
      &.has-number{
        .sectors {
          .sector {
            .rows {
              .row {
                .seat {
                  &:not(.disabled){
                    cursor: pointer;
                  }
                  &.busy:not(.disabled), &:hover:not(.disabled) {
                    background: gray;
                    color: white;
                  }
                  &.selected {
                    background: green;
                    color: white;
                  }
                }
              }
            }
          }
        }
      }
      .sectors {
        display: block;
        white-space: nowrap;
        text-align: center;
        overflow-x: scroll;
        padding: 2rem;
        .sector {
          display: inline-block;
          margin: 0;
          transform: scale(80%);
          transition: transform 0.3s ease-in;
          &:hover {
            transform: scale(90%);
          }
          .rows {
            .row {
              display: block;
              span {
                display: inline-block;
                height: 1.2rem;
                line-height: 1rem;
                text-align: center;
                padding: 0;
              }
              .row-label {
                background: green;
                border-radius: 10rem;
                color: white;
                font-size: 1rem;
                line-height: 1.5rem;
                height: 2rem;
                width: 2rem;
              }
              .seat {
                width: 1.5rem;
              }
            }
          }
        }
      }
    }
  }
}
.present-carnets-tribune {
  margin: 1rem 0;
  padding: 1rem 0;
  .present-carnets-sector {
    display: inline-block;
    min-width: 10rem;
    vertical-align: top;
    margin: 0 0.5rem;
  }
}
