.row {
  white-space: nowrap;
  display: block;
  .row-label {
    display: inline-block;
    width: 2rem;
  }
  .seats {
    display: inline-block;
    .seat {
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
      text-align: center;
      border: 1px solid black;
      border-radius: 0;
      margin: 1px;
      cursor: pointer;
      &.disabled {
        opacity: 0.3;
      }
      &.readonly {
        cursor: initial;
      }
    }
  }
}
