.loader {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 999;
  position: absolute;
  background: white;
  opacity: 1;
  margin: 0;
  padding: 0;
  img {
    height: 40%;
  }
  img, .spinner-wrapper {
    position: absolute;
    width: auto;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
