.user-tickets {
  .court {
    display: none;
    width: 100vw;
    height: 50vh;
    background: #8bbe97;
    position: relative;
    .court-loc-label {
      color: white;
      position: absolute;
      font-size: 5rem;
      z-index: 9999;
      opacity: 0.3;
    }
    .court-field {
      width: 50%;
      height: 60%;
      background: orange;
      position: absolute;
      left: 25%;
      top: 20%;
      div {
        float: left;
        height: 100%;
        border:0.25rem solid white;
      }
      ._2-3-field{
        width: 33%;
      }
      ._1-3-field{
        width: 17%;
        border-left-style: none;
        border-right-style: solid;
        background: #ef7f1d;
        &.first{
          border-left-style: solid;
          border-right-style: dashed;
        }
      }
    }
  }
  .tribunes {
    .tribune {
      background: #EEEEEE;
      &:nth-child(2n+2){
        background: #F2F2F2;
      }
      h3, h4 {
        text-align: center;
      }
      &.no-numbers{
        .sectors{
          .sector{
            border: 1px solid black;
            cursor: pointer;
            position: relative;
            .available-places {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translateX(-50%) translateY(-50%);
              background: rgba(255,255,255,0.5);
              padding: 0.25rem 2.5rem;
              margin-top: 0.25rem;
              h5 {
                font-size: 1.5rem;
                font-weight: 500;
              }
              small {
                font-size: 1rem;
                font-weight: 500;
              }
            }
            .rows{
              .row{
                .row-label{
                  display:none;
                }
                .seat {
                  border:1px dashed gray;
                  position: relative;
                  &.disabled {
                    border: none;
                  }
                }
              }
            }
          }
        }
      }
      padding: 1rem;
      box-sizing: border-box;
      overflow-x: scroll;
      &.has-number{
        .sectors {
          .sector {
            .rows {
              .row {
                .seat {
                  &:not(.disabled){
                    cursor: pointer;
                  }
                  &.disabled, &.busy-reserved, &.busy-ticket {
                    cursor: default;
                  }
                  &.busy-reserved:not(.disabled), &.busy-ticket:not(.disabled) {
                    background: #337ab7;
                    color: white;
                  }
                  &.selected {
                    background: green;
                    color: white;
                  }
                }
              }
            }
          }
        }
      }
      .sectors {
        display: block;
        white-space: nowrap;
        text-align: center;
        overflow-x: scroll;
        padding: 1rem;
        margin-right: 2rem;
        .sector {
          display: inline-block;
          margin: 0;
          transform: scale(80%);
          transition: transform 0.3s ease-in;
          padding: 0 1rem;
          &:hover {
            transform: scale(100%);
            border: 1px solid black;
            background: white;
          }
          .rows {
            .row {
              display: block;
              span {
                display: inline-block;
                height: 1.2rem;
                line-height: 1rem;
                text-align: center;
                padding: 0;
                margin: 0.25rem;
              }
              .row-label {
                background: green;
                border-radius: 10rem;
                color: white;
                font-size: 1rem;
                line-height: 1.5rem;
                height: 2rem;
                width: 2rem;
              }
              .seat {
                width: 1.5rem;
                position: relative;
                &.has-comment {
                  &::before {
                    display: block;
                    content:" ";
                    width: 5px;
                    height: 5px;
                    background: black;
                    position: absolute;
                    top: -2px;
                    right: -2px;
                  }
                }
                .comment-tooltip {
                  display: none;
                  position: absolute;
                  left: 0;
                  top: -10px;
                  transform: translateY(-100%);
                  border: 1px solid blue;
                  background: rgba(0,0,255,0.5);
                  padding: 0.5rem;
                  box-sizing: content-box;
                  &:before {
                    position: absolute;
                    left: 5px;
                    bottom: -10px;
                    content: " ";
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 5px;
                    border-color: blue transparent transparent;
                  }
                }
                &:hover {
                  .comment-tooltip{
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.present-tickets-tribune {
  padding: 1rem 0;
  margin: 1rem 0;
  .present-tickets-sector {
    display: inline-block;
    min-width: 10rem;
    vertical-align: top;
    margin: 0 0.5rem;
  }
}
.select-ticket {
  overflow: hidden;
  .select-ticket-type {
    width: 13rem;
    float: right;
  }
}
@media screen and (max-width: 500px){
  .user-tickets {
    .court {
      height: 10rem;
      .court-loc-label {
        font-size: 1rem;
        opacity: 0.8;
      }
      .court-field {
        width: 90%;
        height: 90%;
        left: 5%;
        top: 5%;
        div {
          float: left;
          height: 100%;
          border:0.25rem solid white;
        }
        ._2-3-field{
          width: 33%;
        }
        ._1-3-field{
          width: 17%;
          border-left-style: none;
          border-right-style: solid;
          background: #ef7f1d;
          &.first{
            border-left-style: solid;
            border-right-style: dashed;
          }
        }
      }
    }
  }
}
.toms100img {
  display: block;
  width: 7rem;
  height: auto;
  margin: 1rem auto;
  display: inline;
}
