.register-panel {
  width: 100%;
  height: 100%;
  background: white;
  left: 0;
  top: 0;
  position: absolute;
  form {
    max-width: 20rem;
    width: 90%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    img {
      width: 100%;
      height: auto;
    }
    input, button {
      width: 100%;
      margin: 0.125rem 0;
    }
  }
  ul {
    li {
      list-style-type: none;
      display: inline-block;
      margin: 0.125rem 0.75rem;
      padding: 0;
      &:first-child{
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
