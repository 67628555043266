.toggle-parent{
  display: inline-block;
  position: relative;
  width: 2rem;
  height: 1.5rem;
  background: white;
  border-radius: 2rem;
  border: 2px solid black;
  .toggle-button{
    width:1.25rem;
    height:1.25rem;
    border-radius: 2rem;
    background: black;
    border: 1px solid black;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }
  &.active {
    .toggle-button {
      transform: translateX(-50%);
      transition: transform 0.2s;
      background: gold;
    }
  }
}
