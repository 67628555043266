table {
  margin-top: 2rem;
  thead {
    tr {
      th {
        text-align: center;
        vertical-align: middle;
      }
    }
  }
}
.date-time-container{
  overflow: hidden;
  .half{
    float: left;
    width: 45%;
    margin: 0.5rem 2%;
  }
}
